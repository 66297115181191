//
// Custom Nav Pills
//

.nav.nav-pills.nav-pills-custom {
  // States
  .show > .nav-link,
  .nav-link {
    border: 1px dashed if(isDarkMode(), $gray-200, $gray-300);
    border-radius: 12px;

    .nav-icon {
      img {
        width: 30px;
        transition: $transition-link;

        &.default {
          display: inline-block;
        }

        &.active {
          display: none;
        }
      }
    }

    &.active {
      background-color: transparent;
      border: 1px solid if(isDarkMode(), $gray-200, $gray-300);
      transition-duration: 1ms;
      position: relative;

      .nav-text {
        color: $gray-800 !important;
        transition: $transition-link;
      }

      .bullet-custom {
        display: block;
      }
    }

    .bullet-custom {
      display: none;
    }
  }
}
